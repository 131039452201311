import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import data from "../../data/howtobuy_data.json";
import pumkin from "../../assets/images/pumkin.svg";
import money from "../../assets/images/money.svg";
import coin from "../../assets/images/coin.svg";

const HowToBuy = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleAccordionChange = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  const AccordionTransitionProps = {
    timeout: {
      enter: 400,
      exit: 400,
    },
  };

  const parseTextToPoints = (text) => {
    return text
      .split(/(?:[\n•*]|\. (?=[A-Z]))/g)
      .map((point) => point.trim())
      .filter((point) => point.length > 0);
  };

  return (
    <Box
      id="howtobuy"
      sx={{
        py: 12,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          position: "relative",
          backgroundColor: "#C2B9E7",
          borderRadius: "40px",
          border: "4px solid #ffff",
          color: "white",
          overflow: "visible",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, sm: 3, md: 5 }}
          alignItems="center"
          sx={{ py: 4 }}
          justifyContent="center"
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "40%" },
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              sx={{
                padding: { xs: 2, sm: 3, md: 5 },
                backgroundColor: "#ffff",
                borderRadius: "40px",
                width: "100%",
                border: "4px solid #7B68CA",
                color: "black",
                overflow: "hidden",
                zIndex: 1,
              }}
            >
              <Typography
                variant="h4"
                align="center"
                fontFamily={"Rubik Wet Paint"}
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    sm: "2rem",
                    md: "2.5rem",
                  },
                }}
              >
                How to Buy
              </Typography>
              <Typography
                variant="body2"
                align="center"
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.1rem",
                  },
                  mt: { xs: 1, sm: 2 },
                }}
              >
                The presale contract is deployed on BSC (Binance Smart Chain)
                hence requires BNB for participating in the presale and for the
                gas fees concerned with the transactions.
              </Typography>
            </Box>

            <Box
              component="img"
              src={money}
              alt="money"
              sx={{
                width: {
                  xs: "150px",
                  sm: "200px",
                  md: "300px",
                  lg: "400px",
                },
                position: "relative",
                left: { xs: "0%", md: "5%" },
                alignSelf: { xs: "center", md: "flex-start" },
                zIndex: 2,
              }}
            />
          </Stack>

          <Stack
            sx={{
              width: { xs: "100%", md: "60%" },
              position: "relative",
            }}
          >
            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Box
                sx={{
                  position: "relative",
                  backgroundColor: "transparent",
                  borderRadius: "40px",
                  width: "100%",
                  height: {
                    xs: "40px",
                    md: "50px",
                  },
                  color: "black",
                  overflow: "visible",
                  zIndex: 1,
                }}
              >
                <Box
                  component="img"
                  src={coin}
                  alt="coin"
                  sx={{
                    position: "absolute",
                    left: {
                      xs: "10%",
                      sm: "15%",
                      md: "20%",
                    },
                    top: {
                      xs: "-20%",
                      sm: "-30%",
                      md: "-40%",
                    },
                    width: {
                      xs: "50px",
                      sm: "60px",
                      md: "80px",
                    },
                    zIndex: 2,
                  }}
                />
                {!isMobile && !isTablet && (
                  <Box
                    component="img"
                    src={pumkin}
                    alt="pumkin"
                    sx={{
                      position: "absolute",
                      right: {
                        xs: "5%",
                        sm: "10%",
                        md: "-10%",
                      },
                      bottom: {
                        xs: "10%",
                        sm: "15%",
                        md: "20%",
                      },
                      width: {
                        xs: "80px",
                        sm: "120px",
                        md: "220px",
                      },
                      zIndex: 3,
                      animation: "float 4s ease-in-out infinite",
                      "@keyframes float": {
                        "0%": {
                          transform: "translateY(0px)",
                        },
                        "50%": {
                          transform: "translateY(-15px)",
                        },
                        "100%": {
                          transform: "translateY(0px)",
                        },
                      },
                    }}
                  />
                )}
              </Box>

              {data.map((howtobuy, index) => (
                <Accordion
                  key={index}
                  expanded={expandedIndex === index}
                  onChange={() => handleAccordionChange(index)}
                  TransitionProps={AccordionTransitionProps}
                  sx={{
                    bgcolor: "white",
                    mb: { xs: 1, sm: 1.5, md: 2 },
                    "&:before": { display: "none" },
                    boxShadow: "none",
                    border: "4px solid #7B68CA",
                    borderRadius: "40px !important",
                    overflow: "hidden",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      p: { xs: 1.5, sm: 2, md: 2.5 },
                      "&.Mui-expanded": {
                        minHeight: {
                          xs: 40,
                          sm: 44,
                          md: 48,
                        },
                        "& .MuiAccordionSummary-content": { mb: 0 },
                      },
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      sx={{
                        fontSize: {
                          xs: "1rem",
                          sm: "1.1rem",
                          md: "1.2rem",
                        },
                      }}
                    >
                      {howtobuy.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: { xs: 1.5, sm: 2, md: 2.5 },
                    }}
                  >
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: { xs: 1, sm: 1.5, md: 2 },
                      }}
                    >
                      {parseTextToPoints(howtobuy.text).map(
                        (point, pointIndex) => (
                          <ListItem
                            key={pointIndex}
                            sx={{
                              display: "list-item",
                              padding: {
                                xs: "2px 0",
                                sm: "3px 0",
                                md: "4px 0",
                              },
                            }}
                          >
                            <ListItemText
                              primary={point}
                              sx={{
                                "& .MuiListItemText-primary": {
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.9rem",
                                    md: "0.95rem",
                                  },
                                  lineHeight: 1.5,
                                },
                              }}
                            />
                          </ListItem>
                        )
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default HowToBuy;
