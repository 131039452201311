import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import {
  Box,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
  Container,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";
import rider from "../../assets/images/rider.svg";
import road from "../../assets/images/road.svg";
import blind from "../../assets/images/blind.svg";
import roadmapData from "../../data/roadmap_data.json";

const Roadmap = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [currentSlide, setCurrentSlide] = useState(0);
  const { stages } = roadmapData;

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === stages.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? stages.length - 1 : prev - 1));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Box
      id="roadmap"
      sx={{
        flexDirection: "column",
        alignItems: "center",
        py: 12,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        component="img"
        src={road}
        alt="road"
        sx={{
          top: { xs: "35%", sm: "15%", md: "0%" },
          position: "absolute",
          width: { xs: "200%", sm: "150%", md: "150%" },
          zIndex: 1,
        }}
      />
      {!isMobile && (
        <Box
          component="img"
          src={blind}
          alt="blind"
          sx={{
            position: "absolute",
            width: { sm: "100px", md: "200px" },
            zIndex: 3,
            left: { sm: "10%", md: "15%" },
            transform: "scaleX(-1)",
            display: { xs: "none", sm: "block" },
          }}
        />
      )}
      <Container
        sx={{
          position: "relative",
          backgroundColor: "#FFD9B4",
          borderRadius: "40px",
          width: "100%",
          border: "4px solid #ffff",
          overflow: "visible",
          py: 4,
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={{ xs: 2, md: 4 }}
          alignItems="center"
        >
          <Box
            sx={{
              padding: { xs: 2, sm: 3, md: 5 },
              position: "relative",
              backgroundColor: "#ffff",
              borderRadius: "40px",
              width: { xs: "100%", md: "50%" },
              border: "4px solid #CC8B2F",

              color: "black",
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            <Typography
              variant="h3"
              fontFamily={"Rubik Wet Paint"}
              sx={{
                textAlign: "start",
                mb: 2,
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                },
              }}
            >
              Roadmap
            </Typography>
            <Typography
              variant="h6"
              fontWeight={800}
              sx={{
                textAlign: "start",
                mb: 2,
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1.25rem",
                },
              }}
            >
              See how Spookipup will develop over the years.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: "start",
                mb: 2,
                fontSize: {
                  xs: "0.75rem",
                  sm: "0.875rem",
                  md: "1rem",
                },
              }}
            >
              SpookiPup's roadmap is designed with a strong focus on building
              and sustaining liquidity and fostering long-term growth. Through
              strategic partnerships, ongoing community engagement, and
              liquidity-enhancing initiatives, SpookiPup aims to create a
              thriving ecosystem that attracts both short-term speculators and
              long-term holders, ensuring its place in the meme token space.
            </Typography>
          </Box>

          <Box
            component="img"
            src={rider}
            alt="rider"
            sx={{
              position: { xs: "static", md: "relative" },
              width: { xs: "120px", sm: "200px", md: "500px" },
              top: { md: "10%" },
              right: { md: "-10%" },
              zIndex: 2,
              alignSelf: { xs: "center", md: "auto" },
            }}
          />
        </Stack>

        <Box
          sx={{
            padding: {
              xs: "15px 0px",
              sm: "20px 0px",
              md: "50px 0px",
            },
            left: { sm: "0%", md: "10%" },
            flexDirection: isMobile ? "column" : "row",
            position: "relative",
            backgroundColor: "#ffff",
            borderRadius: "40px",
            width: { xs: "100%", sm: "100%", md: "80%" },

            border: "4px solid #CC8B2F",

            color: "black",
            overflow: "hidden",
            zIndex: 1,
          }}
          {...swipeHandlers}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{
                opacity: 0,
                x: isMobile ? 0 : currentSlide > prevSlide ? -100 : 100,
              }}
              animate={{ opacity: 1, x: 0 }}
              exit={{
                opacity: 0,
                x: isMobile ? 0 : currentSlide < prevSlide ? -100 : 100,
              }}
              transition={{ duration: 0.3 }}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  width: isMobile ? "100%" : "100%",
                  p: 2,
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={800}
                  sx={{
                    color: "#CC8B2F",
                    fontWeight: "bold",
                    mb: 1,
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    textAlign: "center",
                  }}
                >
                  {stages[currentSlide].number}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight={800}
                  sx={{
                    color: "#000",
                    mb: 2,
                    fontSize: {
                      xs: "1.2rem",
                      sm: "1.5rem",
                    },
                    textAlign: "center",
                  }}
                >
                  {stages[currentSlide].title}
                </Typography>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={2}
                  useFlexGap
                  sx={{ flexWrap: "wrap" }}
                  justifyContent="center"
                >
                  {stages[currentSlide].content.map((point, Index) => {
                    const [label, description] = point.split(": ");
                    return (
                      <Typography
                        key={Index}
                        variant="body1"
                        sx={{
                          color: "#666",
                          fontSize: {
                            xs: "0.875rem",
                            sm: "1rem",
                          },
                          backgroundColor: "#f5f5f5",
                          p: 1,
                          borderRadius: 1,

                          textAlign: "center",
                          width: isMobile
                            ? "100%"
                            : stages[currentSlide].content.length === 3
                              ? "30%"
                              : "45%",
                        }}
                      >
                        <b>{label}:</b> {description}
                      </Typography>
                    );
                  })}
                </Stack>
              </Box>
            </motion.div>
          </AnimatePresence>
          {!isMobile && (
            <IconButton
              onClick={prevSlide}
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
              }}
            >
              <ChevronLeft />
            </IconButton>
          )}

          {!isMobile && (
            <IconButton
              onClick={nextSlide}
              sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
              }}
            >
              <ChevronRight />
            </IconButton>
          )}
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            sx={{
              position: "absolute",
              bottom: isTabletOrMobile ? 10 : -40,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {stages.map((_, index) => (
              <Box
                key={index}
                onClick={() => setCurrentSlide(index)}
                sx={{
                  width: 12,
                  height: 12,

                  borderRadius: "50%",
                  bgcolor: currentSlide === index ? "#CC8B2F" : "#FFD9B4",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    bgcolor: currentSlide === index ? "#CC8B2F" : "#E5A35F",
                  },
                }}
              />
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Roadmap;
