import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./components/layout/nav";
import Home from "./components/layout/home";
import About from "./components/layout/about";
import HowToBuy from "./components/layout/howToBuy";
import Tokenomics from "./components/layout/tokenomics";
import Roadmap from "./components/layout/roadmap";
import FAQ from "./components/layout/Faq";
import Footer from "./components/layout/footer";
import "./App.css"; // Import custom styles
import RubikWetPaint from "./fonts/RubikWetPaint-Regular.ttf";
import Itim from "./fonts/Itim-Regular.ttf";
import AnimatedSection from "./components/common/AnimatedSection";

const FontStyles = `
  @font-face {
    font-family: 'Rubik Wet Paint';
    src: url(${RubikWetPaint}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Itim';
    src: url(${Itim}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

const style = document.createElement("style");
style.textContent = FontStyles;
document.head.appendChild(style);
// Create a custom theme with background color and typography
const theme = createTheme({
  palette: {
    background: {
      default: "linear-gradient(180deg, #B2FFF9 0%, #FFFFFF 100%)",
    },
  },
  typography: {
    fontFamily: "Itim, Arial, sans-serif",
    h1: {
      fontFamily: "Rubik Wet Paint",
    },
    h2: {
      fontFamily: "Rubik Wet Paint",
    },
    h3: {
      fontFamily: "Rubik Wet Paint",
    },
    h4: {
      fontFamily: "Rubik Wet Paint",
    },
    h5: {
      fontFamily: "Rubik Wet Paint",
    },
    h6: {
      fontFamily: "Rubik Wet Paint",
    },
    body1: {
      fontFamily: "Itim",
    },
    body2: {
      fontFamily: "Itim",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(180deg, #B2FFF9 90%, #FFFFFF 100%)",
          // backgroundAttachment: "fixed", // This prevents the gradient from scrolling
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />

      {/* Home section doesn't need animation as it's the first view */}
      <Home />

      <AnimatedSection direction="up">
        <About />
      </AnimatedSection>

      <AnimatedSection direction="up">
        <HowToBuy />
      </AnimatedSection>

      <AnimatedSection direction="up">
        <Tokenomics />
      </AnimatedSection>

      <AnimatedSection direction="up">
        <Roadmap />
      </AnimatedSection>

      <AnimatedSection direction="up">
        <FAQ />
      </AnimatedSection>

      <AnimatedSection direction="up">
        <Footer />
      </AnimatedSection>
    </ThemeProvider>
  );
}

export default App;
