import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import data from "../../data/faq_data.json";
import pumkin from "../../assets/images/pumkin.svg";
import confuse from "../../assets/images/confuse.svg";
import coin from "../../assets/images/coin.svg";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionChange = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  // Function to render answer content
  const renderAnswer = (answer) => {
    if (Array.isArray(answer)) {
      return (
        <Stack spacing={1}>
          {answer.map((item, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: { xs: "0.875rem", sm: "1rem" },
                pl: index === 0 ? 0 : 2, // Add padding for list items except first line
              }}
            >
              {index === 0 ? item : `• ${item}`}
            </Typography>
          ))}
        </Stack>
      );
    }

    // Handle links in text
    if (typeof answer === "string") {
      const whitepaperRegex = /doc\.spookipup\.meme/g;
      const emailRegex = /info@spookipup\.meme/g;
      const home = /Home-Page/g;
      let processedAnswer = answer;

      // Replace whitepaper URL with link
      processedAnswer = processedAnswer.replace(
        whitepaperRegex,
        (match) =>
          `<a href="https://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`
      );

      processedAnswer = processedAnswer.replace(
        home,
        (match) => `<a href="https://${match}" >${match}</a>`
      );
      // Replace email with mailto link
      processedAnswer = processedAnswer.replace(
        emailRegex,
        (match) => `<a href="mailto:${match}">${match}</a>`
      );

      return (
        <Typography
          sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
          dangerouslySetInnerHTML={{ __html: processedAnswer }}
        />
      );
    }

    return (
      <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
        {answer}
      </Typography>
    );
  };

  const AccordionTransitionProps = {
    timeout: {
      enter: 400,
      exit: 400,
    },
  };

  // Split FAQs into two columns
  const midpoint = Math.ceil(data.length / 2);
  const firstColumnFaqs = data.slice(0, midpoint);
  const secondColumnFaqs = data.slice(midpoint);

  const renderFAQColumn = (faqs, startIndex = 0) => (
    <Stack spacing={1} sx={{ flex: 1 }}>
      {faqs.map((faq, index) => (
        <Accordion
          key={index + startIndex}
          expanded={expandedIndex === index + startIndex}
          onChange={(_, isExpanded) =>
            handleAccordionChange(index + startIndex, isExpanded)
          }
          TransitionProps={AccordionTransitionProps}
          sx={{
            bgcolor: "white",
            "&:before": { display: "none" },
            boxShadow: "none",
            border: "4px solid #7B68CA",
            borderRadius: "40px !important",
            overflow: "hidden",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "&.Mui-expanded": {
                minHeight: 48,
                "& .MuiAccordionSummary-content": { mb: 0 },
              },
            }}
          >
            <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
              {faq.q}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{renderAnswer(faq.a)}</AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );

  return (
    <Box
      id="faq"
      sx={{
        py: 12,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Box
        component="img"
        src={coin}
        alt="coin"
        sx={{
          position: "absolute",
          left: { xs: "5%", md: "70%" },
          top: { xs: "-5%", md: "-5%" },
          width: { xs: "50px", sm: "60px", md: "80px" },
          zIndex: 2,
          display: { xs: "none", md: "block" },
        }}
      />
      <Box
        component="img"
        src={coin}
        alt="coin"
        sx={{
          position: "absolute",
          left: { xs: "50%", md: "50%" },
          top: { xs: "-5%", md: "20%" },
          width: { xs: "50px", sm: "60px", md: "80px" },
          zIndex: 2,
          display: { xs: "none", md: "block" },
        }}
      />
      <Box
        component="img"
        src={coin}
        alt="coin"
        sx={{
          position: "absolute",
          left: { xs: "5%", md: "20%" },
          top: { xs: "-5%", md: "5%" },
          width: { xs: "50px", sm: "60px", md: "80px" },
          zIndex: 2,
          display: { xs: "none", md: "block" },
        }}
      />
      <Container
        sx={{
          position: "relative",
          backgroundColor: "#BFBAF4",
          borderRadius: "40px",
          p: { xs: 1.5, sm: 2, md: 3, lg: 4 },
          width: "100%",

          border: "4px solid #ffff",
          color: "white",
          overflow: "visible",
          mt: { xs: "60px", md: "100px" },
        }}
      >
        {/* Decorative images */}
        <Box
          component="img"
          src={coin}
          alt="coin"
          sx={{
            position: "absolute",
            left: { xs: "30%", sm: "25%", md: "20%" },
            top: "2%",
            width: { xs: "30px", sm: "50px", md: "70px" },
            zIndex: 2,
          }}
        />
        <Box
          component="img"
          src={pumkin}
          alt="pumkin"
          sx={{
            position: "absolute",
            right: "5%",
            top: { xs: "-10%", md: "-15%" },
            width: { xs: "100px", sm: "150px", md: "200px" },
            zIndex: 2,
          }}
        />
        <Box
          component="img"
          src={confuse}
          alt="confuse"
          sx={{
            position: "absolute",
            left: "5%",
            top: { xs: "-10%", md: "-15%" },
            width: { xs: "100px", sm: "150px", md: "200px" },
            zIndex: 2,
          }}
        />
        <Typography
          variant="h4"
          align="center"
          fontFamily={"Rubik Wet Paint"}
          sx={{
            mb: 1,
            pt: 2,
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
          }}
        >
          F.A.Q
        </Typography>
        <Typography
          variant="body2"
          align="center"
          fontWeight={800}
          sx={{
            mb: 3,
            fontSize: { xs: "0.875rem", sm: "1rem" },
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{ position: "relative", zIndex: 1 }}
        >
          {renderFAQColumn(firstColumnFaqs)}
          {renderFAQColumn(secondColumnFaqs, midpoint)}
        </Stack>
      </Container>
    </Box>
  );
};

export default FAQ;
