import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { Box } from "@mui/material";

const AnimatedSection = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: false, // Set to false to allow multiple fade in/out
  });

  const springs = useSpring({
    opacity: inView ? 1 : 0,
    config: { duration: 1000 }, // Slower duration for smoother fade
  });

  return (
    <Box ref={ref}>
      <animated.div style={springs}>{children}</animated.div>
    </Box>
  );
};

export default AnimatedSection;
